
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import BankAccount from "./components/BankAccount.vue";
	import BankModal from "./components/BankModal.vue";
	import FeeMessage from "./components/FeeMessage.vue";
	import type { BankAccountModel, BankModel } from "@/models/Fund";

	@Component({ components: { BankAccount, BankModal, FeeMessage } })
	export default class extends Vue {
		$refs!: {
			BankModal: BankModal;
		};

		@Inject() public readonly alert!: AlertMethod;
		@Prop() public id?: string;

		protected data: BankAccountModel | null = null;
		protected options: BankModel[] | null = null;

		protected get prefix(): string {
			return this.id ? '編輯' : '新增';
		}

		beforeMount(): void {
			this.load();
			post<BankModel[]>("/api/Fund/GetBanks").then(list => this.options = list);
		}

		private async load() {
			this.data = null;
			try {
				// 不管怎樣都從後台取得編輯模型
				this.data = await post<BankAccountModel>("/api/Fund/GetBankAccount", { Id: this.id });
				// console.log(this.data);
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
				this.$router.back();
			}
		}

		protected get bankName(): string | null {
			if(!this.data?.BankCode) return null;
			return this.data.BankCode + " " + this.data.BankName;
		}

		protected bank(e: Event): void {
			e.preventDefault();
			(e.target as HTMLInputElement).blur();
			this.$refs.BankModal.show();
		}

		protected setBank(b: BankModel): void {
			this.data!.BankCode = b.Code;
			this.data!.BankId = b.Id;
			this.data!.BankName = b.Name;
		}

		protected async send(): Promise<void> {
			await post<boolean>("/api/Fund/EditBankAccount", { ...this.data });
			this.$router.back();
		}
	}
