
	import { Component, Inject, Prop } from "vue-property-decorator";

	import { now, today, toISO } from "@common/date";
	import Base from "@/views/Base";
	import { FundRecordType } from "@/models/Fund";
	import Record from "./components/Record.vue";
	import type { FundIndexModel, FundRecordModel } from "@/models/Fund";

	@Component({ components: { Record } })
	export default class extends Base {
		@Prop() public id!: string;
		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;

		protected start: Date = today();
		protected end: Date = today();

		protected data: FundRecordModel[] | null = null;
		protected fund: FundIndexModel | null = null;

		created(): void {
			post<FundIndexModel>("/api/Fund", { Id: this.id }).then(v => this.fund = v);
		}

		mounted(): void {
			this.load();
		}

		protected get withdraw(): FundRecordModel[] {
			return this.data?.filter(r => r.Type == FundRecordType.Withdraw) ?? [];
		}

		protected get appropriate(): FundRecordModel[] {
			return this.data?.filter(r => r.Type == FundRecordType.Appropriate) ?? [];
		}

		protected sum(records: FundRecordModel[]): number {
			return records.reduce((a, b) => a + b.Amount, 0);
		}

		private async load(): Promise<void> {
			this.data = null;
			try {
				this.data = await post<FundRecordModel[]>("/api/Fund/Record", {
					Start: toISO(this.start),
					End: toISO(this.end),
				});
				this.update = now();
			} catch(e: unknown) {
				if(e instanceof Error) await this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
				this.$router.back();
			}
		}

		protected get title(): string {
			return "營收帳戶";
			// if(!this.fund) return "";
			// return this.fund.Fund.OwnerName + "的營收帳戶";
		}

		protected onChange(dates: Date[]): void {
			this.start = dates[0];
			this.end = dates[1];
			this.load();
		}
	}
