
	import { Component, Prop, Vue } from "vue-property-decorator";

	import type { FundIndexModel } from "@/models/Fund";

	@Component
	export default class extends Vue {

		@Prop() public data!: FundIndexModel;

		protected detail(id: string): void {
			this.$router.push('/fund/record/' + id);
		}
	}
