
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import type { BankAccountModel } from "@/models/Fund";

	@Component
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;

		@Prop() public data!: BankAccountModel;

		protected edit(): void {
			this.$router.push("/fund/editaccount/" + this.data.Id);
		}

		protected async del(): Promise<void> {
			if(await this.confirm("確定要刪除此常用銀行帳戶嗎？", "刪除帳戶", "warning")) {
				try {
					await post<boolean>("/api/Fund/DeleteAccount", { Id: this.data.Id });
					this.$emit("del");
				} catch(e) {
					await this.alert((e as Error).message, "刪除失敗", "error");
				}
			}
		}
	}
