
	import { Component, Inject, Vue } from "vue-property-decorator";

	import BankAccount from "./components/BankAccount.vue";
	import type { BankAccountModel } from "@/models/Fund";

	@Component({ components: { BankAccount } })
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		protected data: BankAccountModel[] | null = null;

		beforeMount(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			try {
				this.data = await post<BankAccountModel[]>("/api/Fund/CommonBank");
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
				this.$router.back();
			}
		}

		protected create(): void {
			this.$router.push('/fund/editaccount');
		}

		protected del(b: BankAccountModel): void {
			this.data!.splice(this.data!.indexOf(b), 1);
		}
	}
